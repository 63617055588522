<template>  
<!-- green--text text--darken-4 -->
  <v-container>
    <v-card elevation="0" class="d-flex mb-2 justify-center" style="border-radius: 24px 24px 8px 8px;;">
      <v-card-title 
        class="pa-4 px-6 grey--text text--darken-3 font-weight-bold text-uppercase" 
        :style="($vuetify.breakpoint.xs ? 'font-size: 18px;' : 'font-size: 20px;')"
      >
        Clientes - agregar o editar
      </v-card-title>
    </v-card>

    <v-alert
      v-if="existenCobradoresBajaConClientesRelacionados"
      cols="12" sm="12" md="12" 
      elevation="0"
      class="mb-2"
      border="left"
      colored-border
      color="warning"
      :value="''"
      @input="() => {}"
    >
      <div class="d-flex grey--text text--darken-2 align-center ">
        <v-icon class="" color="warning">mdi-information</v-icon>
        <div class="pl-2">
          <b>AVISO:</b> {{ defaultWarningTextOnCobradoresBajaConClientesRelacionados }}
        </div>
      </div>
    </v-alert>

    <!-- FILTROS CLIENTES -->
    <v-card v-if="(planesActivos.length > 0 && usuariosActivos.length > 0) || existenCobradoresBajaConClientesRelacionados" class="mt-5 mb-5" elevation="1">
      <v-row class="v-list-item">

        <v-col 
          cols="12" sm="6" md="6"
          class="pt-3"
        > 
          <v-autocomplete
            v-model="filter_cobrador"
            class="my-0"
            :label="`Filtrar por cobrador asignado`"
            :items="cobradoresSelectFilter"
            :hint="(filter_cobrador && IS_DEV_USER) ? `Id: ${filter_cobrador}` : ''"
            persistent-hint
            clearable
          ></v-autocomplete>
        </v-col> 

      </v-row>
    </v-card>
<!-- FILTROS CLIENTES -->

    <Jumbotron 
      v-if="planesActivos.length == 0 && !tableLoading && !existenCobradoresBajaConClientesRelacionados"
      btnText="Agregar plan"
      redirect="planes"
    >
      Antes de registrar clientes se necesita agregar o dar de alta al menos un Plan.
    </Jumbotron>

    <Jumbotron 
      v-else-if="usuariosActivos.length == 0 && !tableLoading && !existenCobradoresBajaConClientesRelacionados"
      btnText="Agregar cobrador"
      redirect="users"
    > 
      Antes de registrar clientes se necesita agregar o dar de alta al menos un Usuario con rol de Cobrador.
    </Jumbotron>

    <DataTable
      v-else-if="(planesActivos.length > 0 && usuariosActivos.length > 0) || existenCobradoresBajaConClientesRelacionados"
      ref="dtable_clientes"
      title="Clientes"
      subtitle="Clientes registrados"
      :headers="headers"
      :items="clienteTable.items"
      :loading="tableLoading"
      :showPlanAction="true"
      :toolbarActions="true"
      :toolbarTitle="false"
      :highlightEditBtnWithColor="filter_cobrador && !cobradorSelectedFilter.estatus && existenCobradoresBajaConClientesRelacionados ? 'orange' : ''"
      tooltipEditarText="Editar cliente"
      extraEliminarInfoText="El cliente se eliminará permanentemente junto a todos sus planes de cliente asociados y no podrán ser recuperados."
      showItemPropToDelete="nombre+apellidos"
      editarTextSubtitle="Cliente"
      :showSelect="true"
      :showExportExcel="true"
      @goToPlan="goToPlan"
      @editItem="clienteTable.editItem"
      @deleteItem="clienteDelete"
    >
      <template v-slot:showExportExcel="{ rowValues }">
        <a id="exportarExcel">
          <download-excel
            header="Clientes"
            :name="`Clientes_${$dayjs().tz().format('DD_MM_YYYY-hh_mm_ss_a')}.xls`"
            :export-fields="clientes_export_fields"
            :data="rowValues"
            :default-value="''"
          >
            Exportar a Excel
          </download-excel>
        </a>
      </template>

      <template slot="toolbarActions">
        <!-- Para que un ref tome efecto el nodo debe existir en el DOM (tambien se toma si esta oculto), 
        en este caso los componentes dentro de Dialog no existen hasta que la accion clienteTable.dialog 
        es true -->
        <v-form ref="form" v-model="validFormCliente">
          <Dialog
            useToolbarStyle
            v-model="clienteTable.dialog"
            btnText="Agregar Cliente"
            :title="`${clienteTable.formTitle} cliente`"
            :subtitle="clienteTable.isEditable ? clienteTable.editedItem.nombre + ' ' + clienteTable.editedItem.apellidos : ''"
            :fullscreen="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"
            @onCloseToolbarBtn="clienteTable.dialog = false"
          >
            <template slot="content">
                <div v-if="clienteTable.isEditable && clienteTable.editedItem.user_logs" class="my-2"> 
                  <b>
                    Cliente capturado por: 
                  </b>
                  {{ clienteTable.editedItem.user_logs.nombre }} 
                  {{ clienteTable.editedItem.user_logs.apellidos }},
                  el
                  {{ $dayjs(clienteTable.editedItem.user_logs.fecha_log).format("DD/MMM/YYYY [a las] h:mm a.") }}
                </div>

                <v-row>
                  <v-col cols="12" sm="6" md="6" class="d-flex align-start"> 
                    <VTextField
                      v-model="clienteTable.editedItem.num_cliente"
                      :rules="[FormValidations.required, FormValidations.onlyInteger]"
                      label="Número de cliente"
                    ></VTextField>
                  </v-col>

                  <v-col cols="12" sm="6" md="6"> 
                    <v-select
                      :label="`Cobrador(es)${clienteTable.isEditable ? ' (reasignar)' : ' (asignar)'}`"
                      v-model="clienteTable.editedItem.id_cobrador"
                      :error="Boolean(existenCobradoresSeleccDadosDeBaja)"
                      :rules="[
                        FormValidations.requiredArray,
                        v => !existenCobradoresSeleccDadosDeBaja || `Existe un cobrador ha sido dado de baja, asigne 
                          sólo Cobradores activos para poder visualizar los planes de este cliente en el 
                          apartado de Cobranza`
                      ]"
                      :items="cobradoresSelect"
                      :hint="`Asignados: ${clienteTable.editedItem.id_cobrador ? clienteTable.editedItem.id_cobrador.length : ''}`"
                      persistent-hint
                      multiple
                    ></v-select>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="6" md="6"> 
                    <VTextField
                      v-model="clienteTable.editedItem.nombre"
                      :rules="[FormValidations.required]"
                      label="Nombre"
                    ></VTextField>
                  </v-col>

                  <v-col cols="12" sm="6" md="6"> 
                    <VTextField
                      v-model="clienteTable.editedItem.apellidos"
                      :rules="[FormValidations.required]"
                      label="Apellidos"
                    ></VTextField>
                  </v-col>

                  <v-col cols="12" sm="6" md="6"> 
                    <VTextField
                      v-model="clienteTable.editedItem.tel"
                      label="Teléfono"
                    ></VTextField>
                  </v-col>

                  <v-col cols="12" sm="6" md="6"> 
                    <VTextField
                      v-model="clienteTable.editedItem.domicilio"
                      label="Domicilio"
                    ></VTextField>
                  </v-col>

                  <v-col cols="12" sm="6" md="6"> 
                    <VTextField
                      v-model="clienteTable.editedItem.domicilio_de_cobro"
                      label="Domicilio de cobro"
                    ></VTextField>
                  </v-col>

                  <v-col cols="12" sm="6" md="6"> 
                    <VTextField
                      v-model="clienteTable.editedItem.colonia"
                      label="Colonia"
                    ></VTextField>
                  </v-col>

                  <v-col cols="12" sm="6" md="6"> 
                    <VTextField
                      v-model="clienteTable.editedItem.ciudad"
                      label="Ciudad"
                    ></VTextField>
                  </v-col>

                  <v-col cols="12" sm="6" md="6"> 
                    <VTextField
                      v-model="clienteTable.editedItem.localidad"
                      label="Localidad"
                    ></VTextField>
                  </v-col>

                  <v-col cols="12" sm="6" md="6"> 
                    <VTextField
                      v-model="clienteTable.editedItem.cp"
                      label="C.P."
                    ></VTextField>
                  </v-col>

                  <v-col cols="12" sm="6" md="6"> 
                    <VTextField
                      v-model="clienteTable.editedItem.pais"
                      label="Pais"
                    ></VTextField>
                  </v-col>

                  <v-col cols="12" sm="6" md="6"> 
                    <VTextField
                      v-model="clienteTable.editedItem.estado"
                      label="Estado"
                    ></VTextField>
                  </v-col>

                  <v-row>
                    <!-- <v-col v-if="clienteTable.isEditable" cols="12" sm="6" md="6"> 
                      <v-switch
                        v-model="clienteTable.editedItem.suspendido"
                        color="amber darken-3"
                        :prepend-icon="`${clienteTable.editedItem.suspendido ? 'mdi-account-clock' : 'mdi-account-check'}`"
                        :label="`${clienteTable.editedItem.suspendido ? 'Estatus: suspendido' : 'Estatus: no suspendido'}`"
                      />
                    </v-col> -->
  
                    <v-col v-if="clienteTable.isEditable" cols="12" sm="6" md="6"> 
                      <v-switch
                        v-model="clienteTable.editedItem.estatus"
                        color="red darken-3"
                        :prepend-icon="`${clienteTable.editedItem.estatus ? 'mdi-account-off' : 'mdi-account-check'}`"
                        :label="`${clienteTable.editedItem.estatus ? 'Estatus: baja' : 'Estatus: alta'}`"
                      />
                    </v-col>
                  </v-row>
                </v-row>
            </template>

            <template slot="actions">
              <!-- <Button text @click="clienteTable.close">Cancelar</Button> -->
                
              <Button 
                text 
                :loading="tableLoading"
                :disabled="!validFormCliente || clienteTable.disableActionBtn" 
                @click="clienteSave"
              >
                Guardar
              </Button>
            </template>
          </Dialog> 
        </v-form>
      </template>
    </DataTable>

  </v-container>

</template>

<script>
import Button from "@/components/ui/Button";
import Dialog from "@/components/ui/Dialog";
import DataTable from "@/components/ui/DataTable";
// import Jumbotron from "@/components/ui/Jumbotron";
import useDataTable from "@/composables/useDataTable";
import { getCurrentInstance, ref, watch, computed, onBeforeUpdate, onUpdated } from "vue";
import { APP_DEBUG_MODE as APP_DEBUG } from "@/utils/Constants";
import { addUserLogToSendToDB, btoaDecodeData } from "@/utils/Functions";
import FormValidations from "@/utils/FormValidations";
import { state, actions, getters, STORE_NAMESPACES } from "@/store";

export default {
  components: {
    Button,
    Dialog,
    DataTable,
    // Jumbotron,
  },
  setup(props) {
    const vm = getCurrentInstance();
    const { APP, USERS, CLIENTES, PLANES } = STORE_NAMESPACES;
    
    const IS_DEV_USER = getters[APP].getIsDevUser.value;
    const APP_DEBUG_MODE = APP_DEBUG || IS_DEV_USER;

    onUpdated(() => { 
      if (vm?.proxy?.$refs?.dtable_clientes?._data?.rowValues?.length) {
        vm.proxy.$refs.dtable_clientes._data.rowValues = [];
      }
    });

    const clientes_export_fields = {
      "Núm. cliente": 'num_cliente',
      "Nombre": 'nombre',
      "Apellidos": 'apellidos',
      "Domicilio": 'domicilio',
      "Domicilio de cobro": 'domicilio_de_cobro',
      "Colonia": 'colonia',
      "Ciudad": 'ciudad',
      "Localidad": 'localidad',
      "CP": 'cp',
      "Pais": 'pais',
      "Estado": 'estado',
      "Teléfono": 'tel',
      "Fecha de registro": {
        field: 'fecha_reg',
        callback: (value) => {
          return `${vm.proxy.$root.$dayjs(value).tz().format('DD/MM/YYYY')}`;
        }
      },
      "Estatus": {
        field: 'estatus',
        callback: (value) => {
          return `${value ? 'Activo' : 'Baja'}`;
        }
      },
      "Cobrador(es) asignado(s)": {
        field: 'id_cobrador',
        callback: (value) => {
          const cobradores = value.map(idCob => {
            const cob = getters[USERS].findById(idCob);
            return `${cob.value.nombre} ${cob.value.apellidos}`
          })
          return `${cobradores}`;
        }
      },
    }

    const headers = [
      { text: "Núm. cliente", value: "num_cliente", align: "center", sortable: true },
      { text: "Nombre", value: "nombre_completo" },
      // { text: "Apellidos", value: "apellidos" },
      { text: "Domicilio de cobro", value: "domicilio_de_cobro" },
      { text: "Localidad", value: "localidad" },
      { text: "C.P.", value: "cp" },
      { text: "Estatus", value: "estatus", sortable: false },
      { text: "Acciones", value: "action", sortable: false }
    ];

    const defaultValuesOnOpen = {};

    const logged_user = getters[USERS].findById(state[APP].idUser);
    const tableLoading = getters[APP].getLoading;
    const dataSource = getters[CLIENTES].getAll;
    const clienteTable = useDataTable(dataSource, defaultValuesOnOpen);
    const planesActivos = getters[PLANES].getActivos;

    const cobradoresActivos = computed(() => getters[USERS].getAllCobradoresOnly.value.filter(cobrador => cobrador.estatus));
    const cobradores = getters[USERS].getAllCobradoresOnly;
    const usuariosActivos = computed(() => getters[USERS].getAllNonAdmin.value.filter(cobrador => cobrador.estatus));

    const clientesActivos = getters[CLIENTES].getActivos;
    const existenCobradoresBajaConClientesRelacionados = computed(() => {
      return cobradores.value.some(cobrador => {
        return clientesActivos.value
          .some(cliente => cliente.id_cobrador.includes(cobrador.id) && !cobrador.estatus);
      });
    });

    const cobradoresSelect = computed(() => {
      return (clienteTable.isEditable ? cobradores : cobradoresActivos).value.sort(item => !item.estatus).map(cobrador => ({ 
        text: `${cobrador.nombre} ${cobrador.apellidos} ${cobrador.estatus ? '' : '(Dado de baja)'}`, 
        value: cobrador.id 
      }));
    });
    const cobradoresSelectFilter = computed(() => {
      return cobradores.value.sort(item => !item.estatus).map(cobrador => ({ 
        text: `${cobrador.nombre} ${cobrador.apellidos} ${cobrador.estatus ? '' : '(Dado de baja)'}`, 
        value: cobrador.id 
      }));
    });
    const existenCobradoresSeleccDadosDeBaja = computed(() => {
      const cobradoresRes = clienteTable.editedItem?.id_cobrador?.some((idCobrador, idx, arr) => {
        const cobrador = getters[USERS].findById(idCobrador).value;
        const cobradorExiste = cobrador.estatus;

        if (!cobradorExiste)
          clienteTable.editedItem.id_cobrador = [];
          
        return !cobradorExiste;
      });
      
      return cobradoresRes;
    });

    const validFormCliente = ref(true);

    const actionFromUserView = vm.proxy.$root.$route.query.user_action || "";

    // El filtro solo tendrá valor inicial al darse de baja un cobrador y redirigirse a esta vista de Clientes
    const qCobradorId = vm.proxy.$root.$route.query.id_cobrador && btoaDecodeData(vm.proxy.$root.$route.query.id_cobrador);

    const defaultCobradorFilter = qCobradorId || "";
    const filter_cobrador = ref(defaultCobradorFilter);
    const cobradorSelectedFilter = computed(() => {
      return getters[USERS].findById(filter_cobrador.value).value;
    });

    if (defaultCobradorFilter) {
      vm.proxy.$root.$router.replace(`clientes`);
    }


    let defaultWarningTextOnCobradoresBajaConClientesRelacionados = "";
    if (!defaultCobradorFilter && existenCobradoresBajaConClientesRelacionados) {
      defaultWarningTextOnCobradoresBajaConClientesRelacionados += `
        Existen clientes activos que tienen cobradores asignados que han sido dados de baja.
        Filtre los clientes por el cobrador que esté dado de baja y quite o asigne a otro cobrador que esté activo (si el 
        filtro muestra resultados), si no se reasigna un cobrador activo a los clientes afectados, éstos últimos 
        no se mostrarán en la vista de Cobranza.`;
    }
    else if (defaultCobradorFilter) {
      const dynamic_text = actionFromUserView == "baja"
        ? `El cobrador que recién fue dado de baja cuenta con clientes relacionados.`
        : `El cobrador que se intentó eliminar cuenta con clientes relacionados.`;

      defaultWarningTextOnCobradoresBajaConClientesRelacionados += `
        ${dynamic_text}
        Por favor quite o asigne a otro cobrador que esté activo a todos los clientes relacionados que se muestran a continuación en la tabla.
        Si no se asigna un cobrador activo a los clientes afectados, éstos últimos 
        no se mostrarán en la vista de Cobranza.`;
    }
    defaultWarningTextOnCobradoresBajaConClientesRelacionados += " Este mensaje se quitará automáticamente cuando no existan inconsistencias.";


    // Filtros
    clienteTable.items = computed(() => {
      return dataSource.value.filter(item => {
        return (!filter_cobrador.value || item.id_cobrador.includes(filter_cobrador.value))
      }) || []
    });

    // Resetear validacion cuando se abre el dialog/modal
    watch(() => clienteTable.dialog, (dialog) => {
      if (dialog) {
        vm.proxy.$refs.form.resetValidation();
      }
    });

    function goToPlan(id) {
      vm.proxy.$root.$router.push(`clientes/${id}/planes`);
    }

    function clienteSave() {
      const clienteData = {...clienteTable.editedItem};

      clienteTable.save(isEditable => {
        clienteData.localidad = clienteData?.localidad?.trim() || null;

        APP_DEBUG_MODE && console.log("Datos cliente a enviar: ", clienteData);
        if (isEditable) {
          /* Este dato no existe en la BD, sino que se compone cuando se trae 
          desde el servidor, por tanto no se necesita para la actualización */
          if (clienteData.nombre_completo) {
            delete clienteData.nombre_completo;
          }

          actions[CLIENTES].UPDATE(clienteData, () => clienteTable.close());
        }
        else {
          // Datos para registrar log de usuario al insertar un nuevo cliente
          addUserLogToSendToDB(clienteData, logged_user.value, "ClienteRegistro");

          actions[CLIENTES].INSERT(clienteData, (newClientId) => {
            vm.proxy.$root.$router.push({
              path: `clientes/${newClientId}/planes`,
              query: { isNewClient: true }
            });
          });
        }
      });
    }

    function clienteDelete(clienteData) {
      actions[CLIENTES].DELETE(clienteData, () => clienteTable.close());
    }

    return {
      clientes_export_fields,
      headers,
      cobradoresSelect,
      cobradoresSelectFilter,
      existenCobradoresSeleccDadosDeBaja,
      planesActivos,
      usuariosActivos,
      clienteTable,
      clienteSave,
      clienteDelete,
      goToPlan,
      validFormCliente,
      tableLoading,
      existenCobradoresBajaConClientesRelacionados,
      defaultWarningTextOnCobradoresBajaConClientesRelacionados,

      filter_cobrador,
      cobradorSelectedFilter,

      // Funcs. de validacion
      FormValidations,

      IS_DEV_USER,
    };
  }
};
</script>
